import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import SilverLabel from "../images/logo-silver-small.svg"
import GradientImage from "../images/silver-gradient.png"

const SilberLabelBeantragen = () => {
  const [steps, setSteps] = useState(() => [true, ...new Array(7).fill(false)])

  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/application-cyber-silver")
    }
  }, [])

  function toggleCard(index) {
    setSteps(previousState => {
      const newSteps = [...previousState].map((step, i) =>
        i === index ? !step : false
      )
      return newSteps
    })
  }

  return (
    <React.Fragment>
      <PageTitle title={`Cyber Trust ${process.env.GATSBY_VARIANT} Silver Label`} />
      <Layout>
        <div className="application-procedure-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>{`Cyber Trust ${process.env.GATSBY_VARIANT} Silber Label`}</a>
              </li>
            </ul>
          </div>
          <div className="application-procedure">
            <h1>{`Cyber Trust ${process.env.GATSBY_VARIANT} Silber Label`}</h1>
            <div className="application-procedure__wrapper">
              <div className="image">
                <img
                  src={SilverLabel}
                  className="mw-100"
                  alt="Cyber Trust Austria Silver Label"
                  title="Cyber Trust Austria Silver Label"
                />
              </div>
              <h3>
                {`Ablauf bei der Beantragung eines Cyber Trust ${process.env.GATSBY_VARIANT} Silber
                Labels`}
              </h3>
              <div className="page__flex">
                <div className="application-procedure__left">
                  <div className="application-procedure__left--block">
                    <div
                      className={`application-procedure__left--box ${
                        steps[0] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(0)}
                      >
                        Online-<strong>Beantragung</strong>
                      </div>
                      <div className="body">
                        Die{" "}
                        <a
                          className="js-popup"
                          onClick={() =>
                            navigate("/label-beantragen?label=SILVER")
                          }
                        >
                          Beantragung
                        </a>{" "}
                        erfolgt online auf dieser Webseite.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box ${
                        steps[1] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(1)}
                      >
                        <strong>Durchführung</strong> des automatisierten Web
                        Scorings (der angegebenen qualifizierten Domäne)
                      </div>
                      <div className="body">
                        Es erfolgt ein eine automatisierte Sicherheitsbewertung
                        der von Ihnen angegebenen Domäne. Diese erfolgt rein
                        passiv und ist völlig ungefährlich.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box ${
                        steps[2] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(2)}
                      >
                        <strong>Beantwortung</strong> des Online Fragebogens
                      </div>
                      <div className="body">
                        Sie erhalten einen Link zur Beantwortung der 25 Fragen
                        des KSV1870 A-Ratings und machen dort ihre Angaben.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box ${
                        steps[3] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(3)}
                      >
                        <strong>Validierung</strong> der Antworten inkl.
                        möglicher Rückfragen
                      </div>
                      <div className="body">
                        Ein Experte validiert die Beantwortung der Fragen
                        hinsichtlich Vollständigkeit, Konsistenz und
                        Plausibilität. Bei Unklarheiten erfolgt eine Rückfrage.
                        Aus dieser Validierung errechnet sich ein vorläufiges
                        Risk Rating und ein Report wird erstellt.
                      </div>
                    </div>
                    {/* <div
                      className={`application-procedure__left--box ${
                        steps[4] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(4)}
                      >
                        <strong>​Durchführung</strong> des Audits durch den
                        ausgewählten Audit-Partner
                      </div>
                      <div className="body">
                        Sie wählen aus der Liste der akkreditierten{" "}
                        <a
                          className="js-popup"
                          onClick={() => navigate("/partner")}
                        >
                          Audit-Partner
                        </a>{" "}
                        den von ihnen gewünschten Partner aus und beauftragen
                        diesen. Gerne sind wir Ihnen dabei behilflich. Die
                        Verrechnung der Audit-Kosten erfolgt direkt zwischen
                        ihnen und dem Audit-Partner. Der Partner führt das Audit
                        auf Basis des Reports zum vorläufigen Risk Rating durch.
                        Es handelt sich dabei um eine Evidenzprüfung, diese kann
                        auch remote stattfinden. Die Terminvereinbarung erfolgt
                        durch Sie.
                      </div>
                    </div> */}
                    <div
                      className={`application-procedure__left--box ${
                        steps[5] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(5)}
                      >
                        <strong>Möglichkeit zur Klärung</strong> offener Punkte
                        & Richtigstellung
                      </div>
                      <div className="body">
                        In einer Klärungsphase können allfällige Unklarheiten
                        zwischen Ihnen und dem Audit-Partner geklärt und
                        gegebenenfalls fehlende Evidenzen nachgereicht werden,
                        um die erforderliche Qualifizierung zu erreichen.​
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box ${
                        steps[6] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(6)}
                      >
                        <strong>Erstellung</strong> des finalen Cyber Risk
                        Ratings
                      </div>
                      <div className="body">
                        Der Audit-Partner erstellt den finalen Bericht, und auf
                        Basis dessen errechnet sich das finale Rating. Den
                        Audit-Bericht erhalten nur sie, der KSV1870 und Cyber
                        Trust Services erhalten nur die finale
                        Rating-Bestätigung beziehungsweise Informationen, bei
                        welchen Punkten es Abweichungen vom vorläufigen Rating
                        gab.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box ${
                        steps[7] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(7)}
                      >
                        <strong>Ausstellung</strong> des Labels & Eintrag in die
                        Cyber Trust Label Datenbank
                      </div>
                      <div className="body">
                        Wenn die Qualifikationskriterien für das Label erfüllt
                        sind (Vorliegen eines A-Ratings von 190 oder besser),
                        dann erhalten Sie eine Label-Bestätigung mit dem
                        Nutzungsrecht des Labels für ein Jahr und werden in die
                        Label-Datenbank eingetragen.​​
                      </div>
                    </div>
                  </div>
                </div>
                <div className="application-procedure__buttons_container">
                  <a
                    onClick={() => navigate("/label-beantragen?label=SILVER")}
                    className="page__btn page__btn--blue js-popup"
                    data-bg="#FFEBD1"
                    data-btnbg="#C89E67"
                    data-child="2"
                    data-rel="rel1"
                  >
                    Jetzt Label beantragen
                  </a>
                  <Link
                    to="/list-question"
                    className="page__btn page__btn--blue-border"
                  >
                    Anforderungsliste
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default SilberLabelBeantragen
